/** @jsx jsx */
import { jsx } from "theme-ui";
import { Card as TCard } from '@theme-ui/components'
import { FC } from 'react';
import { Link } from 'gatsby'
import { Box, Heading, Text } from '@theme-ui/components'

interface CardProps {
  // url: string
  to?: string
  onClick?: any
}

export const Card: FC<CardProps> = (props) => {
  return (
    <TCard as={props.to ? Link : 'div'} {...props} sx={{
      background: '#fff',
      borderRadius: 4,
      display: 'block',
      textDecoration: 'none',
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)'
    }}>
      {props.children}
    </TCard>
  )
}

export const CardImage: FC<{ url: string }> = (props) => {
  return <img src={props.url} sx={{
    height: 300,
    width: '100%',
    borderRadius: '4px 4px 0 0',
    objectFit: 'cover'
  }} />
}

export const CardTitle: FC = (props) => {
  return <Box p={3}>
    <Heading variant="title" sx={{}}>{(props.children as string).substring(0, 75)}</Heading>
  </Box>
}

export const CardIntro: FC = (props) => {
  return <Box p={3}>
    {props.children}
  </Box>
}

export const CardActions: FC = (props) => {
  return <Box p={3}>
    {props.children}
  </Box>
}

export const CardSubtitle: FC = (props) => {
  return <Box p={3} mt={-20} mb={-3}>
    <Text sx={{ fontSize: 2 }}>
      {props.children}
    </Text>
  </Box>
}